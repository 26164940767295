import store from './store/index.js'
import { createRouter, createWebHistory } from "vue-router";

import HomePage from "./components/pages/HomePage";

import registrationSearch from "./components/pages/sammyRegistration"; 
import memberSearch from "./components/pages/memberSearch";
import memberForm from "./components/pages/memberForm";
import allMembers from "./components/pages/allMembers";

import Login from "./components/pages/Login";

//sammy links
import insuranceRegistration from "./components/pages/sammyRegistration";
import editMember from "./components/pages/editMember";
















const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: HomePage },
    // { path: "/about", component: aboutPage },
    { path: "/member/registration", component: registrationSearch },
    { path: "/insurance/member/search", component: memberSearch },
    { path: "/all/members", component: allMembers, meta: {
      requiresAuth: true
    } },
  
   
    { path: "/login/", component: Login },

    // {path: "/dashboard", component:DashBoard, meta: {
    //   requiresAuth: true
    // }},



    { path: "/member/form/:memberId", component: memberForm },
    { path: "/edit/member/:memberId", component: editMember },

    { path: "/insurance/registration/", component: insuranceRegistration },

    
   
    

  ],
});
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
   
    if (!store.getters.isLoggedIn) {
      next({ path: '/' })
    } else {
      next() 
    }
  } else {
    next() 
  }
})

export default router;
