import { createStore } from "vuex";

const store = createStore({
  state: {
    token: null,
    user: null,

    
    cases: [],
    payments:[],
    schools:[],
    students:[],
    secondaryStudents:[],
    voters:[],
    collegeStudents:[],
    members:[],
    newMembers:[],

    
  },
  mutations: {
    clearToken(context) {
      context.commit("removeToken");
    },
    clearUser(context) {
      context.commit("removeUser");
    },
    addToken(context) {
      context.commit("setToken");
    },

    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    removeToken(state) {
      state.token = null;
    },
    removeUser(state) {
      state.user = null;
    },
    
    
    
    
    setPayments(state, payments) {
      state.payments = payments;
    },
    setMembers(state, members){
      state.members=members
    },
    setNewmembers(state, newMembers){
      state.newMembers=newMembers
    },

    setCases(state, cases){
      state.cases=cases
    }
    
    


   
  },
  actions: {
    clearToken(context) {
      context.commit("removeToken");
    },
    clearUser(context) {
      context.commit("removeUser");
    },
    addToken(context) {
      context.commit("setToken");
    },

    
    async Loadmembers(context) {
      const url = `https://api.roberms.com/shangilia/get/sammy/members`;

      const response = await fetch(url, {
        mode: "cors",
        // credentials: "include",
        headers: {
          "Access-Control-Allow-Origin": true,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        //
      }
      const members = [];
      for (const key in responseData) {
        const member = {
          id: key,
          memberserialNumber: responseData[key].id,
          membership_number: responseData[key].membership_number,

          memberNames: responseData[key].memberNames,
          member_idNumber: responseData[key].member_idNumber,
          memberGender: responseData[key].memberGender,
          member_yob: responseData[key].member_yob,
          memberCourt: responseData[key].memberCourt,
          member_Phone: responseData[key].member_Phone,
          spouseNames: responseData[key].spouseNames,
          spouse_Idnumber: responseData[key].spouse_Idnumber,
          spouse_phonenumber: responseData[key].spouse_phonenumber,
          spouse1Names: responseData[key].spouse1Names,
          spouse1_Idnumber: responseData[key].spouse1_Idnumber,
          spouse1_phonenumber: responseData[key].spouse1_phonenumber,
          spouse2Names: responseData[key].spouse2Names,
          spouse2_Idnumber: responseData[key].spouse2_Idnumber,
          spouse2_phonenumber: responseData[key].spouse2_phonenumber,
          spouse3Names: responseData[key].spouse3Names,
          spouse3_Idnumber: responseData[key].spouse3_Idnumber,
          spouse3_phonenumber: responseData[key].spouse3_phonenumber,
          mother_Idnumber: responseData[key].mother_Idnumber,
          motherNames: responseData[key].motherNames,
          mother_yob: responseData[key].mother_yob,

          motherInlaw_names: responseData[key].motherInlaw_names,
          motherInlaw_id: responseData[key].motherInlaw_id,
          motherInlaw_yob: responseData[key].motherInlaw_yob,

          fatherNames: responseData[key].fatherNames,
          father_Idnumber: responseData[key].father_Idnumber,
          father_yob: responseData[key].father_yob,
          fatherInlaw_id: responseData[key].fatherInlaw_id,
          fatherInlaw_names: responseData[key].fatherInlaw_names,
          fatherInlaw_yob: responseData[key].fatherInlaw_yob,

          kidoneNames: responseData[key].kidoneNames,
          kidoneGender: responseData[key].kidoneGender,
          kidOne_yob: responseData[key].kidOne_yob,

          kidtwoNames: responseData[key].kidtwoNames,
          kidtwoGender: responseData[key].kidtwoGender,
          kidTwo_yob: responseData[key].kidTwo_yob,

          kidthreeNames: responseData[key].kidthreeNames,
          kidthreeGender: responseData[key].kidthreeGender,
          kidThree_yob: responseData[key].kidThree_yob,

          kidfourNames: responseData[key].kidfourNames,
          kidfourGender: responseData[key].kidfourGender,
          kidFour_yob: responseData[key].kidFour_yob,

          kidfiveNames: responseData[key].kidfiveNames,
          kidfiveGender: responseData[key].kidfiveGender,
          kidFive_yob: responseData[key].kidFive_yob,

          kidsixNames: responseData[key].kidsixNames,
          kidsixGender: responseData[key].kidsixGender,
          kidSix_yob: responseData[key].kidSix_yob,

          kidsevenNames: responseData[key].kidsevenNames,
          kidsevenGender: responseData[key].kidsevenGender,
          kidSeven_yob: responseData[key].kidSeven_yob,

          kideightNames: responseData[key].kideightNames,
          kideightGender: responseData[key].kideightGender,
          kidEight_yob: responseData[key].kidEight_yob,
          Registration_date: responseData[key].Registration_date,
          otheroneNames: responseData[key].otheroneNames,
          otherOne_yob: responseData[key].otherOne_yob,
          otherOne_gender: responseData[key].otherOne_gender,
          otherOne_relationship: responseData[key].otherOne_relationship,
          othertwoNames: responseData[key].othertwoNames,
          otherTwo_yob: responseData[key].otherTwo_yob,
          otherTwo_gender: responseData[key].otherTwo_gender,
          otherTwo_relationship: responseData[key].otherTwo_relationship,
          otherThreeNames: responseData[key].otherThreeNames,
          otherThree_yob: responseData[key].otherThree_yob,
          otherThree_gender: responseData[key].otherThree_gender,
          otherThree_relationship: responseData[key].otherThree_relationship,
          otherfourNames: responseData[key].otherfourNames,
          otherFour_yob: responseData[key].otherFour_yob,
          otherFour_gender: responseData[key].otherFour_gender,
          otherFour_relationship: responseData[key].otherFour_relationship,
          otherfiveNames: responseData[key].otherfiveNames,
          otherFive_yob: responseData[key].otherFive_yob,
          otherFive_gender: responseData[key].otherFive_gender,
          otherFive_relationship: responseData[key].otherFive_relationship,
        };
        members.push(member);
      }
      context.commit("setMembers", members);
    },


    async LoadPayments(context) {
      const url = `https://api.roberms.com/shangilia/get/payments`;

      const response = await fetch(url, {
        mode: "cors",
        // credentials: "include",
        headers: {
          "Access-Control-Allow-Origin": true,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        //
      }
      console.log(responseData);
      const payments = [];

      for (const key in responseData) {
        const payment = {
          id: key,
          member_id: responseData[key].member_id,
          member_name: responseData[key].member_name,
          case_id: responseData[key].case_id,
          amount: responseData[key].amount,
          payment_date: responseData[key].payment_date,
          paid_by: responseData[key].paid_by,
          reference: responseData[key].payment_reference,
        };
        payments.push(payment);
      }
      context.commit("setPayments", payments);
    },

    async LoadNewmembers(context) {
      const url = `https://api.roberms.com/shangilia/get/new/members`;

      const response = await fetch(url, {
        mode: "cors",
        // credentials: "include",
        headers: {
          "Access-Control-Allow-Origin": true,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        //
      }
      console.log(responseData);
      const newMembers = [];

      for (const key in responseData) {
        const member = {
          id: key,
          member_id: responseData[key].member_id,
          member_name: responseData[key].member_name,
          registration_amount: responseData[key].registration_amount,
          buffer_amount: responseData[key].buffer_amount,
          payment_date: responseData[key].payment_date,
          paid_by: responseData[key].paid_by,
          // processed_by: responseData[key].processed_by,
          reference: responseData[key].reference,
          processed: responseData[key].processed,
        };
        newMembers.push(member);
      }
      context.commit("setNewmembers", newMembers);
    },
    async LoadMpesapayments(context) {
      const url = `https://api.roberms.com/shangilia/get/mpesapayments`;

      const response = await fetch(url, {
        mode: "cors",
        // credentials: "include",
        headers: {
          "Access-Control-Allow-Origin": true,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        //
      }
      console.log(responseData);
      const MpesaPayments = [];

      for (const key in responseData) {
        const payment = {
          id: key,

          name: responseData[key].name,
          phone: responseData[key].phone_number,
          amount: responseData[key].amount,
          payment_date: responseData[key].payment_date,
          account_number: responseData[key].account_number,
          reference: responseData[key].payment_reference,
          processed: responseData[key].processed,
        };
        MpesaPayments.push(payment);
      }
      context.commit("setMpesapayments", MpesaPayments);
    },

    async LoadCases(context) {
      const url = `https://api.roberms.com/shangilia/get/cases`;

      const response = await fetch(url, {
        mode: "cors",
        // credentials: "include",
        headers: {
          "Access-Control-Allow-Origin": true,
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        //
      }
      console.log(responseData);
      const cases = [];

      for (const key in responseData) {
        const casse = {
          id: key,

          case_id: responseData[key].id,
          member_id: responseData[key].member_id,
          Member_name: responseData[key].Member_name,
          dependant_name: responseData[key].dependant_name,
          relationship: responseData[key].relationship,
          Amount_disbursed: responseData[key].Amount_disbursed,
          members_contribution: responseData[key].members_contribution,
          processed: responseData[key].processed,

          
          date: responseData[key].disbursement_date,
          // reference: responseData[key].payment_reference,
          // processed: responseData[key].processed,
        };
        cases.push(casse);
      }
      context.commit("setCases", cases);
    },
  
  
  
  

   
  
  },
  getters: {
    isLoggedIn() {
      return !!localStorage.getItem("token");
    },

   
    
    members: (state) => state.members,
    // members: (state) => state.members,
    newmembers: (state)=>state.newMembers,
    allPayments: (state) => state.payments,
    allcases: (state) => state.cases,
    membercasePayments: (state)=> (mid) => state.cases.filter(vnumber=>vnumber.id==mid),
    allMembers: (state) => state.members,
    memberdetails: (state) => (mid)=>state.members.filter(vnumber=>vnumber.member_idNumber==mid),

    joiningMember: (state) =>(mid)=>state.newMembers.filter(vnumber=>vnumber.member_id==mid),
    

    voterGetter: (state)=>(vid)=>state.voters.filter(vnumber=>vnumber.id_number==vid),

  },


  //
});
export default store;