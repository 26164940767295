<template>
     <div>
    <base-navigation></base-navigation>
   

    
    <base-card>

    <table class="table table-striped table-bordered table-sm">
            <thead>
              <tr>
                <!-- <th><h3>Client Id</h3></th> -->
                <th><small>Membership Number</small></th>
                <th><small>Member Name</small></th>
                <!-- <th><small> Institution Name</small></th> -->
                <th><small>Court</small></th>
                <th><small>Contact</small></th>
                <!-- <th><small>Registration</small></th>
                <th><small>Buffer</small></th>
                <th><small>Case 1</small></th>
                <th><small>Case 2</small></th>
                <th><small>Case 3</small></th>
                <th><small>Case 4</small></th>
                <th><small>Case 5</small></th> -->
                <!-- <th><h6>Application date</h6></th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="member in membersdetails" :key="member.id">
                <!-- <td>{{ payment.client_id}}</td> -->
                <td>
                  <small> {{ member.memberserialNumber }}</small>
                </td>
                <td>
                  <small>{{ member.memberNames  }}</small>
                </td>
  
                <td>
                  <small>{{ member.memberCourt }}</small>
                </td>
                <td>
                  <small>{{ member.member_Phone }}</small>
                </td>
  
                <!-- <td>
                  <small>500</small>
                </td>
                <td>
                  <small>2000</small>
                </td>
  
                <td>
                    
                  <small>{{ payments.filter((col) => col.member_id == 2)[0].amount }}</small>
               
                </td> -->
  
                <!-- <td>{{ new Date(student.reg_date).toLocaleString('nl-NL') }}</td> -->
              </tr>
            </tbody>
          </table>


    </base-card>

</div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      memberId: "",
      member: null,
    };
  },
  methods: {
    async findMember() {
      const response = await axios.get(
        "https://api.roberms.com/shangilia/get/member/id/" + this.memberId
      );
      // console.log(response);
      this.member = response.data;
      this.memberId = response.data.member_idNumber;
    },
    
  },
  created() {
    this.$store.dispatch("Loadmembers");
    console.log(this.memberdetails)
  },
  computed: {
    membersdetails() {
      return this.$store.getters.allMembers;
    },
    
  },

 
};
</script>