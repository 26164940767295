<template>
  
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">Nyamira county</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" href="/">Home</a>
          </li>
          
          
          
        </ul>
        <!-- right side nav -->
        <ul class="navbar-nav pull-right">
          <!-- If is logged in  -->
          
          <li v-if="isLoggedIn" class="nav-item">
            <!-- <a class="nav-link" href="/request/parcel">parcels</a>
            <a class="nav-link" href="/request/parcel">Messages</a> -->
            
            <a class="dropdown-item" href="#" @click="handleLogout">Logout</a>
         
          
            <ul
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="navbarDropdown"
            >
              <li><a class="dropdown-item" href="/parcels">Parcels</a></li>
              <li><a class="dropdown-item" href="/messages">Messages</a></li>
              <li><a class="dropdown-item" href="#" @click="handleLogout">Logout</a></li>
              <!-- <router-link to="/" @click="handleLogout">logout</router-link> -->
            </ul>
          </li> 
          <li v-else class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Profile
            </a>
            <ul
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="navbarDropdown"
            >
              <li><a class="dropdown-item" href="/login">Login</a></li>
              <li><a class="dropdown-item" href="/register">Signup</a></li>
            </ul>
          </li> 
          <!-- <li class="nav-item">
            <a class="nav-link" href="#">Login</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Signup</a>
          </li> -->
        </ul>
        
      </div>
    </div>
  </nav>

</template>

<script>

export default {
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    handleLogout() {
      this.$store.dispatch("clearToken");
      localStorage.removeItem("token"); // clear your user's token from localstorage
      localStorage.removeItem("user"); // clear your user from localstorage

      this.$router.push("/");
    },
  },
  data() {
    return {
      user: "",
    };
  },
  mounted() {
    let user = localStorage.getItem("user");
    this.user = JSON.parse(user);

    setInterval(() => {
      this.handleLogout();
    }, 360000);
  },
}
  
 </script>
<!-- <style scoped>

/* Add a black background color to the top navigation */
.topnav {
    background-color: #333;
    overflow: hidden;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: #04AA6D;
  color: white;
}

/* Right-aligned section inside the top navigation */
.topnav-right {
  float: right;
}
</style> -->
<style scoped>
/* unvisited link */
/* a:link {
  color: rgb(147, 165, 247);
} */

/* visited link */
/* a:visited {
  color: green;
} */

/* mouse over link */
a:hover {
  color: rgb(24, 44, 226);
}

/* selected link */
a:active {
  color: blue;
}
</style>
