<template>
  <div>
    
      <base-navigation></base-navigation>

      <base-card>
      <p class="text-danger">TERMS AND CONDITIONS </p>
      <p>1. The system will solely be used to collect data for Pamoja self help group members only</p>
      <p>2 Data collected will only be used for the purpose of forwarding it to insurance for registration</p>
      <p>3. The leadership assures members of data privacy and their data will not be used for any other purpose than Insurance registrtaion</p>
      <p>4. The information given in the form will be true and accurate</p>
     
     <small> If you agree with the terms click on the button below to register and have your details collected for insurance registration </small>
     <br>

      <router-link class="text-center" to="/member/registration">Click here to fill a form with your details</router-link>
      <br>

      <small><p class="text-muted">Already registered? <a href="/insurance/member/search">Click here </a> to to download your form</p> </small>

      <!-- <a href="/member/registration">Submit details</a>  -->





    
    
    
    </base-card>
   
  </div>
</template>
<script>
export default {
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },

  methods: {
    handleLogout() {
      this.$store.dispatch("clearToken");
      localStorage.removeItem("token"); // clear your user's token from localstorage
      localStorage.removeItem("user"); // clear your user from localstorage

      this.$router.push("/login");
    },
  },
  data() {
    return {
      user: "",
    };
  },
  mounted() {
    let user = localStorage.getItem("user");
    this.user = JSON.parse(user);
  },
};
</script>

<style scoped>
body{margin-top:20px;
background:#eee;
}
.contact-area {
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
}

@media only screen and (max-width:768px) {
    .contact {
        margin-bottom: 60px;
    }
}

.contact input {
    background: #fff;
    border: 1px solid #fff;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #232434;
    font-size: 16px;
    height: 60px;
    padding: 10px;
    width: 100%;
    font-family: 'poppins', sans-serif;
    padding-left: 30px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.contact textarea {
    background: #fff;
    border: 1px solid #fff;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #232434;
    font-size: 16px;
    padding: 10px;
    width: 100%;
    font-family: 'poppins', sans-serif;
    padding-left: 30px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.contact input:focus {
    background: #fff;
    border: 1px solid #fff;
    color: #232434;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 none;
}

.contact textarea:focus {
    background: #fff;
    border: 1px solid #fff;
    color: #232434;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 none;
}

.form-control::placeholder {
    color: #232434;
    opacity: 1;
}

.btn-contact-bg {
    border-radius: 30px;
    color: #fff;
    outline: medium none !important;
    padding: 15px 27px;
    text-transform: capitalize;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    background: #7564e5;
    font-family: 'poppins', sans-serif;
    cursor: pointer;
    width: 100%;
}

.btn-contact-bg:hover,
.btn-contact-bg:focus {
    background: #232434;
    color: #fff;
}

/*START ADDRESS*/

.single_address {
    overflow: hidden;
    margin-bottom: 10px;
    padding-left: 40px;
}

@media only screen and (max-width:768px) {
    .single_address {
        padding-left: 0px;
    }
}

.single_address i {
    background: #f6f6f6;
    color: #7564e5;
    border-radius: 30px;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    float: left;
    margin-right: 14px;
    font-size: 22px;
    -webkit-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.single_address:hover i {
    background: #7564e5;
    color: #fff;
}

.single_address h4 {
    font-size: 18px;
    margin-bottom: 0px;
    overflow: hidden;
    font-weight: 600;
}

.single_address p {
    overflow: hidden;
    margin-top: 5px;
}

.section-title h1 {
    font-size: 44px;
    font-weight: 500;
    margin-top: 0;
    position: relative;
    text-transform: capitalize;
    margin-bottom: 15px;
}
.section-title p {
    padding: 0 10px;
    width: 70%;
    margin: auto;
    letter-spacing: 1px;
}
.section-title {
    margin-bottom: 60px;
}
.text-center {
    text-align: center!important;
}
.carousel-item img {
  max-height: 90vh;
  min-width: auto;
}
.carousel-caption {
  background-color:rgba(250, 244, 244, 0.582);
  border-radius: 55px 55px 55px 55px;
  overflow: hidden;

  
}
button,

.flat {
  background-color: transparent;
  color: #3a0061;
  border: none;
}
.outline {
  background-color: transparent;
  border-color: #270041;
  color: #270041;
}
.flat:hover,
.flat:active,
.outline:hover,
.outline:active {
  background-color: #edd2ff;
}
</style>
