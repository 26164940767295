<template>
  <div>
    <!-- <base-navigation></base-navigation> -->
    <!-- <base-card>
      <form @submit.prevent="submitForm">
        <label for="name">Name:</label>
        <input type="text" id="name" v-model="formData.name" />

        <label for="email">id Number:</label>
        <input type="email" id="email" v-model="formData.id_number" />

        <button type="submit">Submit</button>
      </form>
    </base-card> -->

    <base-card>
      <form @submit.prevent="registerMember">
        <div>
          <div class="parents">
            <strong>EDIT DETAILS AND SUBMIT </strong>
            <!-- <b class="text-danger">{{ memberids }}</b> -->
            <br />
            <small> Fill  details correctly </small>
            <br />
            <!-- <div class="form-row"></div> -->
            <div class="form-row">
              <div class="col-xs-6 col-sm-4 col-md-4 col-xl-4">
                <label for="name">Spouse 1 ID:</label>
                <input
                  class="form-control"
                  type="text"
                  id="name"
                  v-model="formData.spouse1_Idnumber"
                />
              </div>
              <div class="col-xs-6 col-sm-4 col-md-4 col-xl-4">
                <label for="name">Spouse 1 Name:</label>
                <input
                  class="form-control"
                  type="text"
                  id="name"
                  v-model="formData.spouse1Names
"
                />
              </div>
              <div class="col-xs-6 col-sm-4 col-md-4 col-xl-4">
                <label for="name">Spouse 1 YOB:</label>
                <input
                  class="form-control"
                  type="text"
                  id="name"
                  v-model="formData.spouse1_yob"
                />
              </div>

              <br>
              <br>
              <br>
              <br>
              <div class="col-xs-6 col-sm-4 col-md-4 col-xl-4">
                <label for="name">Spouse 2 ID:</label>
                <input
                  class="form-control"
                  type="text"
                  id="name"
                  v-model="formData.spouse2_Idnumber
"
                />
              </div>
              <div class="col-xs-6 col-sm-4 col-md-4 col-xl-4">
                <label for="name">Spouse 2 Name:</label>
                <input
                  class="form-control"
                  type="text"
                  id="name"
                  v-model="formData.spouse2Names"
                />
              </div>

             
              <div class="col-xs-6 col-sm-4 col-md-4 col-xl-4">
                <label for="name">Spouse 2 YOB:</label>
                <input
                  class="form-control"
                  type="text"
                  id="name"
                  v-model="formData.spouse2_Yob
"
                />
              </div>
              <br>
              <br>
              <br>
              <br>
              <div class="col-xs-6 col-sm-4 col-md-4 col-xl-4">
                <label for="name">Spouse 3 ID:</label>
                <input
                  class="form-control"
                  type="text"
                  id="name"
                  v-model="formData.spouse3_Idnumber
"
                />
              </div>
              <div class="col-xs-6 col-sm-4 col-md-4 col-xl-4">
                <label for="name">Spouse 3 Name:</label>
                <input
                  class="form-control"
                  type="text"
                  id="name"
                  v-model="formData.spouse3Names"
                />
              </div>

             
              <div class="col-xs-6 col-sm-4 col-md-4 col-xl-4">
                <label for="name">Spouse 3 YOB:</label>
                <input
                  class="form-control"
                  type="text"
                  id="name"
                  v-model="formData.spouse3_Yob
"
                />
              </div>
             
              

              <br>
              <br>
              <br>
              <br>
              
              CHILDREN DETAILS
<!-- 

          
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <select
                  class="form-control"
                  v-model="formData.memberGender"
                  required
                >
                  <option disabled value="">select Gender</option>
                  <option>Male</option>
                  <option>Female</option>
                </select>
              </div> -->
            </div>
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Your year of Birth"
                  v-model="formData.member_yob"
                  required
                />
              </div>

              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Phone number"
                  v-model="formData.member_Phone"
                />
              </div>
            </div>
            <!-- spouse details
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Spouse One names"
                  v-model="formData.spouse1Names"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Spouse One ID number"
                  v-model="formData.spouse1_Idnumber"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="spouse One phone number"
                  v-model="formData.spouse1_phonenumber"
                />
              </div>
            </div>
            <br />
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Spouse Two names"
                  v-model="formData.spouse2Names"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Spouse two ID number"
                  v-model="formData.spouse2_Idnumber"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="spouse Two phone number"
                  v-model="formData.spouse2_phonenumber"
                />
              </div>
            </div>
            <br />
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Spouse Three names"
                  v-model="formData.spouse3Names"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Spouse Three ID number"
                  v-model="formData.spouse3_Idnumber"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="spouse Three phone number"
                  v-model="formData.spouse3_phonenumber"
                />
              </div>
            </div>
            <br />
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Spouse Four names"
                  v-model="formData.spouse4Names"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Spouse Four ID number"
                  v-model="formData.spouse4_Idnumber"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="spouse four phone number"
                  v-model="formData.spouse4_phonenumber"
                />
              </div>
            </div>
            <br /> -->

            <strong>PARENTS DETAILS</strong>
            <br />
            <small> FIll your parents details correctly </small>
            <br />
            <div class="form-row"></div>
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Mother names"
                  v-model="formData.motherNames"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Mother ID number"
                  v-model="formData.mother_Idnumber"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Mother year of birth"
                  v-model="formData.mother_yob"
                />
              </div>
            </div>
            <div class="form-row">
              <!-- <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Father names"
                  v-model="formData.fatherNames"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Father ID number"
                  v-model="formData.father_Idnumber"
                />
              </div> -->
              <!-- <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Father year of birth"
                  v-model="formData.father_yob"
                />
              </div> -->
            </div>

            Parents inlaw
            <!-- <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Mother inlaw names"
                  v-model="formData.motherInlaw_names"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Mother inlaw ID number"
                  v-model="formData.motherInlaw_id"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-4 col-xl-4">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Mother inlaw year of birth"
                  v-model="formData.motherInlaw_yob"
                />
              </div>
            </div> -->
            

            <br />

            <br />
            <br />
            

            <strong>OTHERE MEMBERS DETAILS</strong>
            <br />
            <small> FIll other members not in the categories above </small>
            <br />
            <div class="form-row"></div>
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="names"
                  v-model="formData.otheroneNames"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Member ID number"
                  v-model="formData.otherOne_yob"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <select class="form-control" v-model="formData.otherOne_gender">
                  <option disabled value="">select Gender</option>
                  <option>Male</option>
                  <option>Female</option>
                </select>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Relationship to memeber"
                  v-model="formData.otherOne_relationship"
                />
              </div>
            </div>
            <br />
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="names"
                  v-model="formData.othertwoNames"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Member ID number"
                  v-model="formData.othertwo_yob"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <select class="form-control" v-model="formData.otherTwo_gender">
                  <option disabled value="">select Gender</option>
                  <option>Male</option>
                  <option>Female</option>
                </select>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Relationship to memeber"
                  v-model="formData.otherTwo_relationship"
                />
              </div>
            </div>
            <!-- father details  -->

            <br />
            <div class="form-row">
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="names"
                  v-model="formData.otherthreeNames"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Member ID number"
                  v-model="formData.otherthree_yob"
                />
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <select
                  class="form-control"
                  v-model="formData.otherThree_gender"
                >
                  <option disabled value="">select Gender</option>
                  <option>Male</option>
                  <option>Female</option>
                </select>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Relationship to memeber"
                  v-model="formData.otherThree_relationship"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
            <base-button>Submit</base-button>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-3 col-xl-3">
            <router-link to="/insurance/registration"> Cancel</router-link>
          </div>
        </div>
      </form>
    </base-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      formData: {
        name: "",
        id_number: "",
      },
    };
  },
  mounted() {
    // Fetch data from your backend API
    this.findMember();
    //   this.memberId = this.$route.params.memberId
  },
  methods: {
    async findMember() {
      const response = await axios.get(
        "https://api.roberms.com/shangilia/get/sammy/member/id/" +
          this.$route.params.memberId
      );
      console.log(response);
      // this.member = response.data;

      this.formData.name = response.data.memberNames;
      this.formData.id_number = response.data.memberNames;
      this.formData.spouse1_Idnumber = response.data.spouse1_Idnumber;
      this.formData.spouse1Names = response.data.spouse1Names;
      this.formData.spouse1_phonenumber = response.data.spouse1_phonenumber;
      this.formData.spouse2_Idnumber = response.data.spouse2_Idnumber;
      this.formData.spouse2Names = response.data.spouse2Names;
      this.formData.spouse2_phonenumber = response.data.spouse2_phonenumber;
    },
    //   fetchFormData() {

    //     // Assume you have an API endpoint to fetch data for a specific record
    //     const recordId = 'your-record-id'; // Replace with your actual record ID
    //     fetch(`/api/records/${recordId}`)
    //       .then(response => response.json())
    //       .then(data => {
    //         // Populate form data with fetched data
    //         this.formData.name = data.name;
    //         this.formData.email = data.email;
    //       })
    //       .catch(error => {
    //         console.error('Error fetching data:', error);
    //       });
    //   },
    submitForm() {
      // Handle form submission here
      // You can send formData to your backend API to update the record
    },
  },
};
</script>

<style scoped>
/* Add your component styles here */
</style>
